import React from "react";
import { useNavigate } from "react-router-dom";
import astronaut from "../Images/now.png";
import foxx from "../Images/foxx.png";
import "./Header.css";
import { toast } from "react-toastify";

function Header() {
  const navigate = useNavigate();

  const handleAboutMeClick = () => {
    navigate("/aboutme");
  };

  const handleMyWorkClick = () => {
    navigate("/projects");
  };

  const notify = () => {
    toast(
      "Oh, come on, leave this girl alone, here is a moving bug on this page, your first task is to catch it :) Secret hint: First letter is: < a > Good luck!",
      {
        className: "myToastClassName",
      }
    );
  };

  const notifysecond = () => {
    toast(
      "Well, here we go again! Try to be a Sherlock Holmes for a moment - Find a bug. Now!"
    );
  };

  return (
    <>
      <div className="main">
        <div className="header">
          <div className="name">
            <div className="textname">
              <h1 className="shine">Hey There!</h1>
              <h1>I am Nini Laliashvili, </h1>
              <h1 className="border">full stack web developer.</h1>
            </div>
            <div className="picturename">
              <img
                onClick={notify}
                className="astronaut"
                src={astronaut}
                alt="Astronaut"
              />
            </div>
          </div>
        </div>
        <div className="button-box">
          <img onClick={notifysecond} className="fox" src={foxx} alt="Fox" />
          <button className="aboutme-mywork" onClick={handleAboutMeClick}>
            About Me
          </button>
          <button className="aboutme-mywork" onClick={handleMyWorkClick}>
            My Work
          </button>
        </div>
      </div>
    </>
  );
}

export default Header;
