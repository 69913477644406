import React from "react";
import "./Contact.css";
import get from "../Images/planet.png";
import insta from "../Images/instagram.png";
import fb from "../Images/facebook.png";
import github from "../Images/github-sign.png";
import link from "../Images/linkedin.png";

function Contact() {
  return (
    <section id="contact" className="contact-section">
      <img className="get" src={get} alt="get" />
      <h2 className="sayhello">SAY HELLO!</h2>
      <p className="huh">
        Whether you have an idea for a project or just want to chat, feel free
        to shoot me an email!
      </p>
      <button
        onClick={() =>
          (window.location.href = "mailto:ninilaliashvili8@gmail.com")
        }
        className="email-button"
      >
        Talk to Me
      </button>
      <div className="footer-container">
        <footer className="footer">
          <div className="contact-details">
            <p>Phone: +972 053-58-15-724</p>
            <p>Email: ninilaliashvili8@gmail.com</p>
            <p>Location: Tel Aviv, Israel</p>
          </div>
          <div className="social-links">
            <a
              href="https://www.facebook.com/nini.laliashvili.98"
              target="_blank"
              rel="noreferrer"
            >
              <img className="socim" src={fb} alt="fb" />
            </a>
            <a
              href="https://www.instagram.com/ninalaliashvili1/"
              target="_blank"
              rel="noreferrer"
            >
              <img className="socim" src={insta} alt="instagram" />
            </a>
            <a
              href="https://www.linkedin.com/in/nini-laliashvili-08a97b223/"
              target="_blank"
              rel="noreferrer"
            >
              <img className="socim" src={link} alt="linkedin" />
            </a>
            <a
              href="https://github.com/NinaLaliashvili"
              target="_blank"
              rel="noreferrer"
            >
              <img className="socim" src={github} alt="git" />
            </a>
          </div>

          <div
            className="map-container"
            style={{ textAlign: "center", marginTop: "20px" }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27064.529049028384!2d34.7273179469853!3d32.013322285461946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1502b312412bb38b%3A0xb2961a65cc489c32!2sBat%20Yam!5e0!3m2!1sen!2sil!4v1708201325639!5m2!1sen!2sil"
              width="500"
              height="400"
              style={{
                border: 0,
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          <div className="copyright">
            <p>© Nini Laliashvili 2023</p>
          </div>
        </footer>
      </div>
    </section>
  );
}

export default Contact;
