import React, { useEffect, useState } from "react";
import "./Loader.css";

function Loader({ onLoaded }) {
  const [code, setCode] = useState("");
  const fullCode = `
    <html>
      <head>
        <title>Loading...</title>
      </head>
    </html>
  `;

  useEffect(() => {
    const timer = setInterval(() => {
      if (code.length < fullCode.length) {
        setCode((prevCode) => prevCode + fullCode[prevCode.length]);
      }
    }, 10);

    setTimeout(() => {
      const el = document.querySelector(".loader-wrapper");
      if (el) el.style.display = "none";
      onLoaded(); // Call the callback function once loading is complete
    }, 2000);

    return () => {
      clearInterval(timer);
    };
  }, [code, onLoaded]);

  return (
    <div className="loader-wrapper">
      <div className="code-editor">
        <pre>{code}</pre>
        <span className="cursor" />
      </div>
    </div>
  );
}

export default Loader;
