import React, { useState, useEffect } from "react";
import HTMLFlipBook from "react-pageflip";
import "./SecondSecretPage.css";

const pages = [
  {
    page: "Page 1",
    text: "Welcome, I'm very glad to see you here!",
  },
  {
    page: "Page 1",
    text: "Welcome, I'm very glad to see you here!",
  },
  {
    page: "Page 1",
    text: "Welcome, I'm very glad to see you here!",
  },
];

function SecondSecretPage() {
  return (
    <>
      <div className="wo">
        <h1 className="woala">
          Welcome to my special page! This is where I share all the cool stuff
          I've used to learn. You'll find lots of info, helpful places, and
          tools here, in this little notebook! Have fun looking around! 😊📚
        </h1>
      </div>
      <div className="second-secret-page">
        <HTMLFlipBook width={450} height={600}>
          {pages.map((content, index) => (
            <div className="page" key={index}>
              <div className="page-content">
                <p>{content.page}</p>
                <p>{content.text}</p>
              </div>
            </div>
          ))}
        </HTMLFlipBook>
      </div>
    </>
  );
}

export default SecondSecretPage;
