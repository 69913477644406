import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import SecretPage from "./Components/SecretPage/SecretPage";
import SecondSecretPage from "./Components/SecondSecretPage/SecondSecretPage";
import Aboutme from "./Components/About Me/Aboutme";
import Experience from "./Components/Experience/Experience";
import Resume from "./Components/Resume/Resume";
import Projects from "./Components/Projects/Projects";
import Contact from "./Components/Contact/Contact";
import Header from "./Components/Header/Header";
import Loader from "./Components/Loader/Loader";
import Bug from "./Components/Bug/Bug";
import Design from "./Components/Header/design";

function MainContent() {
  return (
    <>
      <Bug />
      <Header />
      <Design />
    </>
  );
}

function App() {
  const [loading, setLoading] = useState(true);

  const handleLoader = () => {
    setLoading(false);
  };

  return (
    <div>
      {loading ? (
        <Loader onLoaded={handleLoader} />
      ) : (
        <Routes>
          <Route path="/" element={<MainContent />} />
          <Route path="/aboutme" element={<Aboutme />} />
          <Route path="/experience" element={<Experience />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/secret" element={<SecretPage />} />
          <Route path="/secondSecretPage" element={<SecondSecretPage />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
