import React, { useState, useEffect } from "react";
import "./Aboutme.css";
import mypic from "../Images/mme.svg";
import travel from "../Images/travel.png";
import petcare from "../Images/pet-care.png";
import programming from "../Images/programming.png";
import capy from "../Images/capybar.svg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Element, animateScroll as scroll } from "react-scroll";

function Aboutme() {
  const commands = [
    "> npm install success-in-life",
    "Installing... Success achieved!",
    "> there's a hidden bug moving on the Home Page!",
    "> If you manage to catch it, a secret surprise will be unlocked just for you! Keep your eyes peeled and happy hunting! 🚀",
    "Catch the bug :)",
  ];

  const navigate = useNavigate();

  const handleexperienceclick = () => {
    navigate("/experience");
  };

  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible3, setIsVisible3] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Function to handle the scroll event
  const handleScroll = () => {
    const element1 = document.querySelector(".element1");
    const element2 = document.querySelector(".element2");
    const element3 = document.querySelector(".element3");

    if (window.scrollY + window.innerHeight > element1.offsetTop) {
      setIsVisible1(true);
    }
    if (window.scrollY + window.innerHeight > element2.offsetTop) {
      setIsVisible2(true);
    }
    if (window.scrollY + window.innerHeight > element3.offsetTop) {
      setIsVisible3(true);
    }
  };

  const notifyaboutme = () => {
    toast(
      "Yes, this is me, wondering if you hire me, haha! BTW, second letter is: < r >"
    );
  };

  const notifyaboutcapy = () => {
    toast(
      "If you are capybara lover, you should know that they are excellent swimmers and can remain underwater for up to five minutes at a time to hide from predators <3 "
    );
  };

  const notifyaboutwrite = () => {
    toast("insert your thoughts <3 ");
  };

  const [displayedText, setDisplayedText] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < commands.length) {
      const timer = setTimeout(() => {
        const updatedText = [...displayedText];
        if (charIndex === 0) {
          updatedText.push("");
        }
        updatedText[currentIndex] += commands[currentIndex][charIndex];
        setDisplayedText(updatedText);

        if (charIndex < commands[currentIndex].length - 1) {
          setCharIndex(charIndex + 1);
        } else {
          setCharIndex(0);
          setCurrentIndex(currentIndex + 1);
        }
      }, 50);
      return () => clearTimeout(timer);
    }
  }, [displayedText, currentIndex, charIndex]);

  return (
    <section id="aboutme" className="aboutme-section">
      <div className="intro-container cnt">
        <div>
          <img
            onClick={notifyaboutme}
            src={mypic}
            alt="Nina's Portrait"
            className="portrait"
            key="front"
          />
        </div>
        <div className="story">
          <p className="intro-title ">A Little Bit About Myself</p>
          <p className="bio-text">
            Hello! I'm Nini, a full stack web developer who started the tech
            journey at the
            <a
              className="atag"
              href="https://www.itc.tech/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Israel Tech Challenge.
            </a>
            I love coding. Each code I write shows something new, making every
            day an exciting challenge. ^_^
          </p>
        </div>
      </div>
      <Element name="myElement" className="element1">
        <div
          className={`intro-container content ${isVisible1 ? "animate" : ""}`}
        >
          <div className="story">
            <p className="bio-text">
              But there's more to me than just code. I also enjoy writing. I
              like to put my thoughts and feelings into words. Writing helps me
              express myself and is very calming for me.
            </p>
          </div>
          <div>
            <img
              onClick={notifyaboutwrite}
              className="gif"
              src="https://www.icegif.com/wp-content/uploads/2023/03/icegif-1543.gif"
              alt="description"
            />
          </div>
        </div>
      </Element>
      <Element name="myElement2" className="element2">
        <div
          className={`intro-container content ${isVisible2 ? "animate" : ""}`}
        >
          <div>
            <img
              onClick={notifyaboutcapy}
              src={capy}
              alt="Nina's Portrait"
              className="capy"
              key="front"
            />
          </div>
          <div className="story">
            <p className="bio-text">
              Away from the screen and paper, I’m an outdoor enthusiast. I feel
              peaceful in the mountains when I go hiking. But, the adventure
              doesn’t end there. I also love animals. Spending time with them
              and their unconditional love makes my day better.
            </p>
          </div>
        </div>
      </Element>

      <div className="abilities-container">
        <div className="abilities-title-container">
          <p className="abilities-title">My Main Interests</p>
        </div>
        <div className="abilities-card">
          <div className="ability">
            <img src={programming} alt="coding" className="ability-icon" />
            <p className="ability-text">Coding</p>
          </div>
          <div className="ability">
            <img src={petcare} alt="another ability" className="ability-icon" />
            <p className="ability-text">Animals</p>
          </div>
          <div className="ability">
            <img src={travel} alt="another ability" className="ability-icon" />
            <p className="ability-text">Traveling</p>
          </div>
        </div>
        <Element name="myElement3" className="element3">
          <div
            className={`bio-container content ${isVisible3 ? "animate" : ""}`}
          >
            <div className="terminal-window">
              {displayedText.map((txt, idx) => (
                <p key={idx}>{txt}</p>
              ))}
              <span className="cursor">|</span>
            </div>
          </div>
        </Element>
      </div>
      <div className="last-box">
        <h1 className="ct">Enough about myself, check out Experience ^^:</h1>
        <button onClick={handleexperienceclick} className="exp-but">
          Experience
        </button>
      </div>
    </section>
  );
}

export default Aboutme;
