import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./SecretPage.css";
import icc from "../Images/hahaha.png";

function SecretPage() {
  const navigate = useNavigate();
  const [answer, setAnswer] = useState("");
  const [feedback, setFeedback] = useState("");

  const handleLeave = () => {
    navigate("/");
  };

  const checkAnswer = () => {
    if (answer.toLowerCase() === "array") {
      navigate("/secondSecretPage");
    } else {
      setFeedback(
        "Not quite! Think of a structure that holds elements in a specific sequence."
      );
    }
  };

  return (
    <div className="secretdiv">
      <div className="secret-container">
        <p className="riddle1">
          Yaaaay! Great catch! ^_^ You've found the bug. Now, let's unlock the
          secret page! You need to solve this tech riddle:
        </p>
        <div className="content-container">
          <div className="riddle">
            <p>
              I'm structured and ordered, holding elements in a line. I can be
              multidimensional, but not in space or time. What am I?
            </p>
          </div>

          <input
            type="text"
            placeholder="Your answer..."
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          />

          <button className="submit-answer" onClick={checkAnswer}>
            Submit Answer
          </button>

          <button className="leave-button" onClick={handleLeave}>
            Leave
          </button>
          {feedback && <p className="feedback-message">{feedback}</p>}
        </div>
        {/* <div className="icon-container">
        <img src={icc} alt="3D Image" className="huge-icon" />
      </div> */}
      </div>
    </div>
  );
}

export default SecretPage;
