import React, { useState } from "react";
import "./Projects.css";
import animatedImageSrc from "../Images/astronautt.webp";
import imageTwo from "../Images/im11.png";
import imageThree from "../Images/im12.png";
import imageFour from "../Images/im13.png";
import imageFive from "../Images/im14.png";
import quiz from "../Images/quiz.png";
import imageTwo2 from "../Images/quiz21.png";
import imageThree2 from "../Images/quiz22.png";
import imageFour2 from "../Images/quiz23.png";
import imageFive2 from "../Images/quiz24.png";
import imageSix2 from "../Images/quiz25.png";
import imageSeven2 from "../Images/quiz26.png";
import imageEight2 from "../Images/quiz27.png";
import imageNine2 from "../Images/quiz28.png";
import imageTen2 from "../Images/quiz29.png";
import imageEleven2 from "../Images/quiz210.png";
import imageTwelve2 from "../Images/quiz211.png";
import petAdoption from "../Images/lalala1.png";
import imageTwo3 from "../Images/lalala31.png";
import imageThree3 from "../Images/lalala32.png";
import imageFour3 from "../Images/lalala33.png";
import imageFive3 from "../Images/lalala34.png";
import imageSix3 from "../Images/lalala35.png";
import imageSeven3 from "../Images/lalala36.png";
import imageEight3 from "../Images/lalala37.png";
import myFirstHtmlPage from "../Images/vamos.png";
import myFirstHtmlPage1 from "../Images/vamos.png";

function Projects() {
  const [activeProject, setActiveProject] = useState(null);
  const [imageIndex, setImageIndex] = useState(0);

  const projects = [
    {
      title: "Notes App",
      link: "https://notes-app-nina.netlify.app/",
      images: [imageTwo, imageThree, imageFour, imageFive],
      description:
        "The Notes App is a user-friendly digital platform designed to keep your notes organized and easily accessible. With a clean and intuitive interface, users can quickly jot down their thoughts, create to-do lists, or draft ideas that can be revisited and edited anytime, anywhere.",
      languages: "HTML5, CSS, React",
      detailPage: "/notes-app",
    },
    {
      title: "Fact or Fiction Fun Quiz",
      link: "https://github.com/NinaLaliashvili/Truth-Trials.git",
      images: [
        quiz,
        imageTwo2,
        imageThree2,
        imageFour2,
        imageFive2,
        imageSix2,
        imageSeven2,
        imageEight2,
        imageNine2,
        imageTen2,
        imageEleven2,
        imageTwelve2,
      ],
      description:
        "Engage in a fun quiz to distinguish between facts and fiction across various topics. Test your knowledge and learn new facts in a fun and interactive way.",
      languages: "HTML5, CSS, React, MongoDB, Node.js",
      detailPage: "/fact-or-fiction",
    },
    {
      title: "Pet Adoption Project",
      link: "https://github.com/NinaLaliashvili/Pet-adoption.git",
      images: [
        petAdoption,
        imageTwo3,
        imageThree3,
        imageFour3,
        imageFive3,
        imageSix3,
        imageSeven3,
        imageEight3,
      ],
      description:
        "Connect with adorable pets looking for their forever homes. Explore various pets and find your perfect furry friend through an intuitive adoption process.",
      languages: "HTML5, CSS, React, MongoDB, Node.js",
      detailPage: "/pet-adoption",
    },
    {
      title: "My First HTML Page",
      link: "https://my-first-html-page.netlify.app/",
      images: [myFirstHtmlPage, myFirstHtmlPage1],
      description:
        "A showcase of my journey into web development, featuring my very first HTML page. A simple yet profound step into the vast world of coding.",
      languages: "HTML, CSS",
      detailPage: "/my-first-html-page",
    },
  ];

  const handleProjectClick = (projectTitle) => {
    if (activeProject && activeProject.title === projectTitle) {
      setActiveProject(null);
      setImageIndex(0); // Reset image index when deactivating a project
    } else {
      const project = projects.find((p) => p.title === projectTitle);
      setActiveProject(project);
      setImageIndex(0); // Reset image index when activating a project
    }
  };

  const showNextImage = () => {
    setImageIndex((prevIndex) => (prevIndex + 1) % activeProject.images.length);
  };

  const showPrevImage = () => {
    setImageIndex(
      (prevIndex) =>
        (prevIndex - 1 + activeProject.images.length) %
        activeProject.images.length
    );
  };

  return (
    <div id="projects" style={{ display: "flex", height: "100vh" }}>
      <div className="sidebar">
        {projects.map((project, index) => (
          <div
            key={index}
            className={`project-name ${
              activeProject?.title === project.title ? "active" : ""
            }`}
            onClick={() => handleProjectClick(project.title)}
          >
            {project.title} <span className="arrow">→</span>
          </div>
        ))}
      </div>
      <div className="center-content">
        {activeProject ? (
          <div className="project-details-area">
            <div className="project-image">
              <img
                src={activeProject.images[imageIndex]}
                alt={`${activeProject.title} ${imageIndex + 1}`}
                style={{
                  maxWidth: "100%",
                  maxHeight: "400px",
                  objectFit: "cover",
                }}
              />
              {activeProject.images.length > 1 && (
                <>
                  <button className="prev-image" onClick={showPrevImage}>
                    &lt;
                  </button>
                  <button className="next-image" onClick={showNextImage}>
                    &gt;
                  </button>
                </>
              )}
            </div>
            <div className="project-details">
              <h2>{activeProject.title}</h2>
              <p>{activeProject.description}</p>
              <p>
                <strong>Languages Used:</strong> {activeProject.languages}
              </p>
              {activeProject.link && (
                <a
                  href={activeProject.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button"
                >
                  Visit Project
                </a>
              )}
            </div>
          </div>
        ) : (
          <img
            src={animatedImageSrc}
            alt="Animated center"
            className="animated-center-image"
          />
        )}
      </div>
    </div>
  );
}

export default Projects;
